import { Layout, Result404 } from 'components';
import { ResultError } from 'components/errors';
import { MainFiltersProvider } from 'components/main-filters/main-filters.context';
import { AccountSettingsView } from 'features/account-settings';
import { Amenities } from 'features/amenities/routes/amenities';
import { LoginView } from 'features/auth';
import {
  BookingDetailsView,
  DayView,
  RecurringBookingDetailsView,
  RecurringBookingsAddBookingView,
  RecurringBookingsView,
  StandardBookingsView,
} from 'features/bookings';
import { FixedBookingSingleBookingDetailsView } from 'features/bookings/routes/fixed-booking-single-booking-details';
import { RecurringBookingBookings } from 'features/bookings/routes/recurring-booking-bookings';
import { RecurringBookingInvoiceView } from 'features/bookings/routes/recurring-booking-invoice';
import { RecurringBookingsCommentsView } from 'features/bookings/routes/recurring-bookings-comments';
import { WeekView } from 'features/bookings/routes/week-view';
import { GroupView } from 'features/community/routes/group-list';
import { GroupTermView } from 'features/community/routes/group-term';
import { GroupTermViewEdit } from 'features/community/routes/group-term-view';
import { ReportViewDetail } from 'features/community/routes/report-details';
import { ReportPostView } from 'features/community/routes/report-post';
import {
  AddCompanyView,
  CompaniesView,
  CompanyDetailsView,
} from 'features/companies';
import { CompanyTermView } from 'features/companies/routes/company-term';
import { ComapnyTermViewEdit } from 'features/companies/routes/company-term-view';
import { CompanyInfoView } from 'features/company-info/routes/company-info';
import { ContactUsView } from 'features/company-info/routes/contact-us';
import { PrivacyPolicyView } from 'features/company-info/routes/privacy-policy';
import { CreateFacilityView } from 'features/facilities/routes/create-facility';
import { FacilitiesView } from 'features/facilities/routes/facilities';
import { UpdateFacilityView } from 'features/facilities/routes/update-facility';
import { AddFieldView, FieldDetailsView, FieldsView } from 'features/fields';
import { AddPackageView } from 'features/package/routes/add-package';
import { AcademyTermView } from 'features/package/routes/cancellation-policy';
import { CancellationViewEdit } from 'features/package/routes/cancellation-term-view';
import { PackageBookingView } from 'features/package/routes/package-booking';
import { PackageDetailsView } from 'features/package/routes/package-details';
import { PackageView } from 'features/package/routes/package-list';
import { PackageBookingDetails } from 'features/package/routes/package-view';
// import { PackageView } from 'features/package/routes/package-list';
import { ReportsView } from 'features/reports';
import { LabelView } from 'features/social-label/routes/label-list';
import { LabelUpdateView } from 'features/social-label/routes/label-update';
import { SocialEdit } from 'features/social-match/routes/social-edit';
import { SocialListView } from 'features/social-match/routes/social-list';
import { SocialView } from 'features/social-match/routes/social-view';
import { Sports } from 'features/sports/routes/sports';
import { UserQuestionUpdateView } from 'features/user-level/routes';
import { BadgeView } from 'features/user-level/routes/badge';
import { BadgeUpdateView } from 'features/user-level/routes/badge-update';
import { QuestionView } from 'features/user-level/routes/questions';
import { AdminUsersView, AdminUserUpdateView } from 'features/users';
import { EndUserUpdateView } from 'features/users/routes/end-user-update';
import { EndUsersView } from 'features/users/routes/end-users';
import {
  UsersWithWalletView,
  WalletTransactionDetailsView,
  WalletTransactionHistoryView,
} from 'features/wallet';
import { WithdrawViewDetails } from 'features/withdraw/routes/withdraw-details';
import { UsersWithWithdrawView } from 'features/withdraw/routes/withdraw-list';
import { WithdrawViewReject } from 'features/withdraw/routes/withdraw-reject';
import { WithdrawView } from 'features/withdraw/routes/withdraw-view';
import { createBrowserRouter, Navigate, RouteObject } from 'react-router-dom';

import { AuthenticatedRoute } from './authenticated-route';
import { ROUTES } from './constants';
import { RestrictedRoute } from './restricted-route';
import { UnauthenticatedRoute } from './unauthenticated-route';

export const routerConfig: RouteObject[] = [
  {
    errorElement: <ResultError />,
    children: [
      {
        path: ROUTES.LOGIN,
        element: (
          <UnauthenticatedRoute>
            <LoginView />
          </UnauthenticatedRoute>
        ),
      },
      {
        path: ROUTES.HOME,
        element: (
          <AuthenticatedRoute>
            <Layout />
          </AuthenticatedRoute>
        ),
        children: [
          {
            path: ROUTES.HOME,
            element: <Navigate to={ROUTES.BOOKINGS} replace />,
          },
          {
            path: ROUTES.ACCOUNT_SETTINGS,
            element: <AccountSettingsView />,
          },
          {
            path: ROUTES.BOOKINGS,
            element: (
              <MainFiltersProvider>
                <StandardBookingsView />
              </MainFiltersProvider>
            ),
            children: [
              {
                path: ROUTES.BOOKINGS_EDIT,
                element: <BookingDetailsView />,
              },
            ],
          },
          {
            path: ROUTES.FIXED_BOOKINGS,
            element: (
              <MainFiltersProvider>
                <RecurringBookingsView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.FIXED_BOOKINGS,
            element: <RecurringBookingDetailsView />,
            children: [
              {
                path: ROUTES.FIXED_BOOKING_DETAILS,
                element: <RecurringBookingBookings />,
                children: [
                  {
                    path: ROUTES.FIXED_BOOKINGS_EDIT,
                    element: <FixedBookingSingleBookingDetailsView />,
                  },
                ],
              },
              {
                path: ROUTES.FIXED_BOOKING_DETAILS_INVOICE,
                element: <RecurringBookingInvoiceView />,
              },
              {
                path: ROUTES.FIXED_BOOKING_DETAILS_COMMENTS,
                element: <RecurringBookingsCommentsView />,
              },
            ],
          },
          {
            path: ROUTES.FIXED_BOOKINGS_CREATE,
            element: <RecurringBookingsAddBookingView />,
          },
          {
            path: ROUTES.DAY_VIEW,
            element: (
              <MainFiltersProvider>
                <DayView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.WEEK_VIEW,
            element: (
              <MainFiltersProvider>
                <WeekView />
              </MainFiltersProvider>
            ),
          },
          {
            path: ROUTES.USERS,
            element: <Navigate to={ROUTES.USERS_APP} replace />,
          },
          {
            path: ROUTES.USERS_APP,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='users.view'
              >
                <EndUsersView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.USERS_APP_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='users.edit'
                  >
                    <EndUserUpdateView />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.USERS_ADMIN,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='adminUsers.view'
              >
                <AdminUsersView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.USERS_ADMIN_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='adminUsers.edit'
                  >
                    <AdminUserUpdateView />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.SOCIAL,
            element: <Navigate to={ROUTES.SOCIAL_LIST} replace />,
          },
          {
            path: ROUTES.SOCIAL_LIST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='social.view'
              >
                <SocialListView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.SOCIAL_DETAILS,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='social.edit'
                  >
                    <SocialView />
                  </RestrictedRoute>
                ),
              },
              {
                path: ROUTES.SOCIAL_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='social.edit'
                  >
                    <SocialEdit />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.LABEL,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='social.view'
              >
                <LabelView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.LABEL_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='social.edit'
                  >
                    <LabelUpdateView />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.QUESTION,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='userLevel.view'
              >
                <MainFiltersProvider>
                  <QuestionView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.USERS_QUESTION_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='userLevel.edit'
                  >
                    <UserQuestionUpdateView />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.BADGE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='userLevel.view'
              >
                <BadgeView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.BADGE_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='userLevel.edit'
                  >
                    <BadgeUpdateView />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.COMMUNITY_TC,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='community.view'
              >
                <GroupTermView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.COMMUNITY_GROUPS_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='community.edit'
                  >
                    <GroupTermViewEdit />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.REPORT_POST,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='community.view'
              >
                <MainFiltersProvider>
                  <ReportPostView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.REPORT_POST_DETAILS,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='community.edit'
                  >
                    <ReportViewDetail />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          //companies
          {
            path: ROUTES.COMPANIES,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='company.view'
              >
                <MainFiltersProvider>
                  <CompaniesView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.COMPANIES_EDIT,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='company.view'
              >
                <CompanyDetailsView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.COMPANIES_CREATE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='company.add'
              >
                <AddCompanyView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FACILITIES,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='facilities.view'
              >
                <MainFiltersProvider>
                  <FacilitiesView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FACILITIES_CREATE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.FACILITIES}
                requiredPermission='facilities.create'
              >
                <CreateFacilityView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FACILITIES_EDIT,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.FACILITIES}
                requiredPermission='facilities.edit'
              >
                <UpdateFacilityView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FIELDS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='fields.view'
              >
                <MainFiltersProvider>
                  <FieldsView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FIELDS_CREATE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='fields.add'
              >
                <AddFieldView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.FIELDS_EDIT,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='fields.view'
              >
                <FieldDetailsView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.SPORTS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='sports.view'
              >
                <Sports />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.AMENITIES,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='amenities.view'
              >
                <Amenities />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.REPORTS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='reports.view'
              >
                <MainFiltersProvider>
                  <ReportsView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.WALLET,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='wallet.view'
              >
                <UsersWithWalletView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.COMMUNITY_GROUPS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='community.view'
              >
                <MainFiltersProvider>
                  <GroupView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },

          {
            path: ROUTES.PACKAGE_MASTER,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='package.view'
              >
                <MainFiltersProvider>
                  <PackageView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.PACKAGE_BOOKINGS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='package.view'
              >
                <MainFiltersProvider>
                  <PackageBookingView />
                </MainFiltersProvider>
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.PACKAGE_BOOKING_DETAILS,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='package.view'
                  >
                    <PackageBookingDetails />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.PACKAGE_CREATE,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='package.view'
              >
                <AddPackageView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.PACKAGE_EDIT,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='fields.view'
              >
                <PackageDetailsView />
              </RestrictedRoute>
            ),
          },
          {
            path: ROUTES.CANCELLATION_POLICY,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='package.view'
              >
                <AcademyTermView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.CANCELLATION_POLICY_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='community.edit'
                  >
                    <CancellationViewEdit />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.WITHDRAW,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='withdraw.view'
              >
                <UsersWithWithdrawView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.WITHDRAW_detail,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='withdraw.view'
                  >
                    <WithdrawView />
                  </RestrictedRoute>
                ),
              },
              {
                path: ROUTES.WITHDRAW_detail_data,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='withdraw.view'
                  >
                    <WithdrawViewDetails />
                  </RestrictedRoute>
                ),
              },
              {
                path: ROUTES.WITHDRAW_REJECT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='withdraw.view'
                  >
                    <WithdrawViewReject />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.WALLET_TRANSACTIONS,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='wallet.view'
              >
                <WalletTransactionHistoryView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.WALLET_TRANSACTION_DETAILS,
                element: <WalletTransactionDetailsView />,
              },
            ],
          },
          {
            path: ROUTES.COMPANY_TC,
            element: (
              <RestrictedRoute
                redirectPath={ROUTES.HOME}
                requiredPermission='community.view'
              >
                <CompanyTermView />
              </RestrictedRoute>
            ),
            children: [
              {
                path: ROUTES.COMPANY_TC_EDIT,
                element: (
                  <RestrictedRoute
                    redirectPath={ROUTES.HOME}
                    requiredPermission='community.view'
                  >
                    <ComapnyTermViewEdit />
                  </RestrictedRoute>
                ),
              },
            ],
          },
          {
            path: ROUTES.COMPANY_INFO,
            element: <CompanyInfoView />,
            children: [
              {
                path: ROUTES.CONTACT_US,
                element: <ContactUsView />,
              },
              {
                path: ROUTES.PRIVACY_POLICY,
                element: <PrivacyPolicyView />,
              },
            ],
          },
        ],
      },

      {
        path: ROUTES.NOT_FOUND,
        element: <Result404 />,
      },
    ],
  },
];

export const router = createBrowserRouter(routerConfig);
